import React from "react";
import { TiLocation, TiPhone } from "react-icons/ti";
import { MdEmail } from "react-icons/md";
// import FaMapLocationDot from "react-icons/fa";
const ContactUs = () => {
  // TODO :: add call button CTA
  return (
    <section className="font-primary min-h-screen bg-gradient-to-b from-gray-800 to-white py-16 px-5 md:px-32">
      <div
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
      >
        <p className="text-white text-4xl font-bold font-arabic_heading text-end">
          اتصل بنا
        </p>
        <div className="lg:grid lg:grid-cols-2 gap-5 mt-32">
          <div className="flex flex-col justify-center items-center sm:grid sm:grid-cols-2 gap-10 text-center">
            <div className="h-[250px] w-[250px] flex flex-col p-10 justify-around items-center bg-gradient-to-b from-white to-gray-400 rounded-2xl shadow-2xl">
              <TiLocation className="w-10 h-10" />
              <div>
                <p className="font-bold uppercase text-xl">موقع الشركة</p>
                <p>١٠ الشيخ العسوي أحمد، النزهة الجديدة، القاهرة</p>
              </div>
              <a href="https://goo.gl/maps/dAKBtaRAr25frcuH6">
                <div className="uppercase font-bold border-2 border-black px-7 animate-pulse">
                  تنقل
                </div>
              </a>
            </div>

            <div className="h-[250px] w-[250px] flex flex-col p-10 justify-around items-center bg-gradient-to-b from-white to-gray-400 rounded-2xl shadow-2xl">
              <TiPhone className="w-10 h-10" />
              <p className="font-bold uppercase text-xl">الهاتف</p>
              <div className="text-end">
                <p>+٢٠١٢٢٣٥٢٢١٤٦</p>
                <p>٢٦٢٠٣٦٨٤</p>
              </div>
              <a href="tel:+201223522146">
                <div className="uppercase font-bold border-2 border-black px-7 animate-pulse">
                  اتصل
                </div>
              </a>
            </div>
            <div className="h-[250px] w-[250px] flex flex-col p-10 justify-around items-center bg-gradient-to-b from-white to-gray-400 rounded-2xl shadow-2xl">
              <MdEmail className="w-10 h-10" />
              <p className="font-bold uppercase text-xl">البريد الإلكتروني</p>
              <div className="text-end">
                <p>contact@gama4ac.com</p>
              </div>
            </div>
          </div>
          {/* <div>MAPS</div> */}
        </div>
      </div>
    </section>
  );
};

export default ContactUs;

import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavigationBarTab = ({ isActive, title, to }) => {
  return (
    <li>
      <Link
        to={to}
        className={` ${
          isActive
            ? " block py-2 pl-3 pr-4 text-black bg-white rounded md:bg-transparent md:text-white md:p-0 font-primary"
            : "block py-2 pl-3 pr-4 text-gray-400 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-300 md:p-0 font-primary"
        }`}
        // aria-current="page"
      >
        {title}
      </Link>
    </li>
  );
};

export default NavigationBarTab;

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import NavigationBarTab from "./NavigationBarTab";

const NavigationBar = () => {
  const location = useLocation();
  const [isBurgerMenuOpened, setIsBurgerMenuOpened] = useState(false);
  return (
    <nav className="bg-gray-800">
      <div className="max-w-screen-lg flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to={"/"} className="flex items-center">
          <span className="text-white self-center text-2xl font-bold whitespace-nowrap font-primary">
            <span className="uppercase tracking-widest">GAMA</span>{" "}
            <span className="text-lg font-normal text-gray-400 tracking-tight">
              Business-to-Business
            </span>
          </span>
        </Link>
        <button
          onClick={() => {
            setIsBurgerMenuOpened(!isBurgerMenuOpened);
          }}
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-800"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={
            isBurgerMenuOpened
              ? "w-full md:block md:w-auto fixed top-12 left-0 z-50 bg-gray-800"
              : "hidden w-full md:block md:w-auto"
          }
          id="navbar-default"
        >
          <ul
            onClick={() => {
              setIsBurgerMenuOpened(false);
            }}
            className="font-medium flex flex-col-reverse p-4 md:p-0 mt-4 border border-gray-800 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 text-end"
          >
            <NavigationBarTab
              isActive={location.pathname === "/contact-us"}
              title={"اتصل بنا"}
              to={"/contact-us"}
            />
            <NavigationBarTab
              isActive={location.pathname === "/available-products"}
              title={"المنتجات المتاحة"}
              to={"/available-products"}
            />
            <NavigationBarTab
              isActive={location.pathname === "/"}
              title={"الصفحة الرئيسية"}
              to={"/"}
            />
            {/* <NavigationBarTab
              isActive={location.pathname === "/about-us"}
              title={"About Us"}
              to={"/about-us"}
            /> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;

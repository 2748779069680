import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="bg-gradient-to-b from-gray-800 to-white min-h-screen py-32 lg:px-48 lg:py-40 font-primary flex justify-center text-right">
      {/* Hero Left Content */}
      <div
        data-aos="fade-right"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="leading-[5px] px-5 xl:px-16 flex flex-col justify-center"
      >
        <p className="text-gray-300 text-xl lg:text-2xl font-arabic_heading mb-5">
          مرحبا في
        </p>
        <div className="text-4xl lg:text-5xl font-bold text-white tracking-wide">
          GAMA{" "}
          <span className="text-gray-200 italic tracking-tighter">
            Business To Business
          </span>
          <p className="text-2xl lg:text-4xl mt-5 font-normal font-arabic_heading">
            إعادة تعريف طريقة شراء وبيع مكيفات الهواء
            <br /> متجر إلكتروني لمكيفات الهواء للأعمال التجارية. فقط للأعمال
            التجارية
          </p>
        </div>
        <div className="w-full flex flex-col lg:flex-row gap-5 mt-12 lg:justify-end">
          <div>
            <Link to={"/available-products"}>
              <button className="w-full px-7 py-6 font-bold text-black border-gray-800 border-2 rounded-xl hover:bg-gray-800 duration-300 ease-in hover:text-white">
                المنتجات المتاحة
              </button>
            </Link>
          </div>
          {/* <div>
            <Link to={"/contact-us"}>
              <button className="w-full px-7 py-6 font-bold text-black border-gray-800 border-2 rounded-xl hover:bg-gray-800 duration-300 ease-in hover:text-white">
                اتصل بنا
              </button>
            </Link>
          </div> */}
        </div>
      </div>
      {/* Hero Right Content */}
      {/* <div
        data-aos="fade-left"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        className="m-12 lg:m-0 flex overflow-hidden rounded-3xl flex-col justify-center items-center "
      >
        <img
          className="rounded-3xl h-1/2 opacity-100 "
          src="https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt="aim"
        />
      </div> */}
    </section>
  );
};

export default Hero;

import React, { useEffect } from "react";
import NavigationBar from "./components/layout/NavigationBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Hero from "./components/hero/Hero";
import Aos from "aos";
import AvailableProducts from "./components/available-products/AvailableProducts";
import ContactUs from "./components/contact-us/ContactUs";
import AboutUs from "./components/about-us/AboutUs";
const App = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/available-products" element={<AvailableProducts />} />
            {/* <Route path="/about-us" element={<AboutUs />} /> */}
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import Container from "./Container";
import Product from "./Product";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "description_ar", headerName: "الوصف", width: 270 },
  { field: "quantity", headerName: "الكمية", width: 150 },
  { field: "name", headerName: "الكود", width: 200 },
];
const initialState = {
  pagination: {
    paginationModel: { page: 0, pageSize: 100 },
  },
  sorting: {
    sortModel: [{ field: "description_ar", sort: "asc" }],
  },
};

const AvailableProducts = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    axios
      .get("/api/products")
      .then((repsonse) => {
        setProducts(repsonse.data.products);
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  if (isLoading) {
    return (
      <div className="flex justify-center items-center w-full min-h-screen bg-gradient-to-b from-gray-800 to-white">
        <span class="loader"></span>
      </div>
    );
  } else {
    return (
      <section className="font-primary min-h-screen bg-gradient-to-b from-gray-800 to-white py-16 px-5 md:px-32">
        <div
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
        >
          <p className="text-white text-3xl font-bold font-arabic_heading text-end">
            المنتجات المتاحة
          </p>
          <p className="text-white text-lg font-bold text-end">
            يتم تحديث المخزون تلقائيًا
          </p>
          <p className="text-2xl underline text-end text-gray-400 pt-5">
            للشراء يرجى الاتصال بنا
          </p>
          {/* LG */}
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-end mt-8">
              <div className="w-full h-2 bg-gradient-to-l from-transparent to-gray-800 rounded-2xl mr-4" />
              <p className="text-white text-2xl font-arabic_heading">ال-جي</p>
              <div className="w-8 h-2 bg-gradient-to-r from-transparent to-gray-800 rounded-2xl ml-4" />
            </div>
            {/* Container */}
            {/* <Container>
              {products?.map((product) => {
                if (product?.brand === "lg") {
                  return (
                    <Product
                      title={product?.name}
                      description={product?.description}
                      quantity={product?.quantity}
                    />
                  );
                }
              })}
            </Container> */}
            <div
              style={{ height: 300, width: "100%" }}
              className="bg-white my-10"
            >
              <DataGrid
                getRowId={(row) => row._id}
                rows={products?.filter((product) => product?.brand === "lg")}
                columns={columns}
                disableRowSelectionOnClick
                pageSizeOptions={[10, 15]}
                // sx={{
                //   direction: "rtl",
                // }}
                initialState={initialState}
              />
            </div>
          </div>
          {/* Sharp */}
          <div className="flex flex-row items-center">
            <div className="w-full h-2 bg-gradient-to-l from-transparent to-gray-800 rounded-2xl mr-4" />
            <p className="text-white text-2xl font-arabic_heading">شارب</p>
            <div className="w-8 h-2 bg-gradient-to-r from-transparent to-gray-800 rounded-2xl ml-4" />
          </div>
          <div
            style={{ height: 300, width: "100%" }}
            className="bg-white my-10"
          >
            <DataGrid
              getRowId={(row) => row._id}
              rows={products?.filter((product) => product?.brand === "sharp")}
              columns={columns}
              disableRowSelectionOnClick
              pageSizeOptions={[10, 15]}
              initialState={initialState}
              // sx={{
              //   direction: "rtl",
              // }}
            />
          </div>
          {/* Tornado */}
          <div className="flex flex-row items-center">
            <div className="w-full h-2 bg-gradient-to-l from-transparent to-gray-800 rounded-2xl mr-4" />
            <p className="text-white text-2xl font-arabic_heading">تورنيدو</p>
            <div className="w-8 h-2 bg-gradient-to-r from-transparent to-gray-800 rounded-2xl ml-4" />
          </div>
          <div
            style={{ height: 300, width: "100%" }}
            className="bg-white my-10"
          >
            <DataGrid
              getRowId={(row) => row._id}
              rows={products?.filter((product) => product?.brand === "tornado")}
              columns={columns}
              disableRowSelectionOnClick
              pageSizeOptions={[10, 15]}
              // sx={{
              //   direction: "rtl",
              // }}
              initialState={initialState}
            />
          </div>
        </div>
      </section>
    );
  }
};

export default AvailableProducts;
